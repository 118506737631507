<template>
  <div>
    <b-row align-h="center" v-if="dataLoading">
      <b-spinner
        class="m-5"
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>

    <template v-else>
      <b-card>
        <b-row>
          <!-- <b-col md="3">
            <b-form-group
              label="Collection Account"
              invalid-feedback="account is required."
              ref="acc"
            >
              <v-select
                v-model="loadObj.accountID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="accLoadList"
                :reduce="(opt) => opt.id"
                label="account"
                :clearable="false"
                placeholder="Select collection account"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="3">
            <b-form-group
              label="Date"
              invalid-feedback="Date is required."
              ref="date"
            >
              <flat-pickr
                :config="config"
                v-model="rangeDate"
                class="form-control"
                placeholder="Select Date."
                @on-change="setDate"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" class="" v-if="false">
            <b-form-group
              label="Select Fee Type"
              invalid-feedback="Please select fee type."
              ref="type"
            >
              <a class="my-buttons font-weight-bold">
                <span v-if="!showAll" @click="showAll = true">Show More</span>
                <span v-else @click="showAll = false">Show Less</span>
              </a>

              <div
                :class="{ 'my-collapsing': !showAll }"
                class="border p-50 rounded"
              >
                <!-- style="
                  border: 1px solid lightgray;
                  padding: 5px;
                  border-radius: 5px;
                " -->
                <b-button
                  class="btn-icon m-25"
                  v-for="fee in feeList"
                  :key="fee.id"
                  :variant="
                    loadObj.feesTypeIDs.includes(fee.id)
                      ? 'primary'
                      : 'outline-primary'
                  "
                  @click="selectFeetype(fee.id)"
                >
                  {{ fee.feeType }}
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <!-- @click="showData()" -->
            <b-button
              variant="primary"
              @click="LoadRecords()"
              block
              class="mt-2"
              :disabled="request"
            >
              <b-spinner
                v-if="request"
                small
                variant="light"
                type="grow"
                label="Spinning"
              ></b-spinner>
              <span v-else>Show</span>
            </b-button>
          </b-col>
          <b-col md="3">
            <b-button
              variant="success"
              @click="print()"
              block
              class="mt-2"
              :disabled="request || printing"
            >
              <b-spinner
                v-if="printing"
                small
                variant="light"
                type="grow"
                label="Spinning"
              ></b-spinner>
              <template v-else>
                <feather-icon icon="PrinterIcon" class="mr-50" />
                <span>Print</span>
              </template>
            </b-button>
          </b-col>
        </b-row>
      </b-card>

      <h4>Collection Summary</h4>
      <b-card>
        <b-row v-if="selectedID.length > 0" class="mb-1">
          <b-col md="3">
            <b-form-group
              label="Deposit Account"
              invalid-feedback="account is required."
              ref="d_acc"
            >
              <v-select
                v-model="myObj.newAccountID"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="accList"
                :reduce="(opt) => opt.id"
                label="account"
                :clearable="false"
                placeholder="Select collection account"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-button
              variant="primary"
              class="btn-icon mt-2"
              @click="deposit()"
              :disabled="saving"
            >
              Deposit (Rs. {{ totalAmount.toLocaleString() }})
            </b-button>
          </b-col>
        </b-row>
        <b-table
          ref="summaryTable"
          class=""
          :busy="request || saving"
          :items="items"
          :fields="fields2"
          show-empty
          responsive
          hover
          selectable
          :select-mode="'multi'"
          @row-selected="onRowSelect($event)"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center my-3">
              <b-spinner
                style="width: 3rem; height: 3rem"
                type="grow"
                variant="primary"
                label="Spinning"
              ></b-spinner>
            </div>
          </template>

          <template #head(inquiries)="data">
            <div class="text-center">
              {{ data.label }}
              <div class="font-small-1 text-center">Total | Completed</div>
            </div>
          </template>
          <template #head(registrations)="data">
            <div class="text-center">
              {{ data.label }}
              <div class="font-small-1 text-center">Total | Pending</div>
            </div>
          </template>
          <template #head(admissions)="data">
            <div class="text-center">
              {{ data.label }}
              <div class="font-small-1 text-center">Total | Pending</div>
            </div>
          </template>

          <template #head(campus)="data">
            <div>
              <b-form-checkbox
                inline
                :checked="
                  selectedID.length > 0 && selectedID.length == items.length
                "
                @change="(e) => setSelectAll(e)"
                :disabled="saving"
              >
                <span class="font-small-2">{{ data.label }}</span>
              </b-form-checkbox>
            </div>
          </template>

          <template #cell(campus)="data">
            <b-form-checkbox
              inline
              :checked="selectedID.includes(data.item.campusID)"
              @change="(e) => setSelected(e, data.index)"
              :disabled="saving"
            >
              {{ data.item.campusName }}
            </b-form-checkbox>
          </template>
          <template #cell(inquiries)="data">
            <div class="text-center">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
              |
              <b-badge variant="light-success">
                {{ data.item.inquiriesCompleted }}
              </b-badge>
            </div>
          </template>
          <template #cell(registrations)="data">
            <div class="text-center">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
              |
              <b-badge variant="light-danger">
                {{ data.item.registrationPending }}
              </b-badge>
            </div>
          </template>
          <template #cell(admissions)="data">
            <div class="text-center">
              <b-badge variant="light-primary">
                {{ data.value }}
              </b-badge>
              |
              <b-badge variant="light-danger">
                {{ data.item.admissionPending }}
              </b-badge>
            </div>
          </template>
          <template #cell(amount)="data">
            <b-badge variant="light-primary"> Rs. {{ data.value }} </b-badge>
          </template>

          <template #cell(totalRegistration)="data">
            <b-badge variant="light-primary">
              {{ data.value }}
            </b-badge>
          </template>
          <template #cell(totalEnrollment)="data">
            <b-badge variant="light-primary">
              {{ data.value }}
            </b-badge>
          </template>
          <template #cell(totalAmount)="data">
            <b-badge variant="light-primary">
              {{ data.value }}
            </b-badge>
          </template>
          <template #cell(actions)="data">
            <b-button
              variant="primary"
              v-b-tooltip.hover.bottomleft
              title="View Details"
              class="btn-icon"
              @click="viewDetails(data.item)"
            >
              <feather-icon icon="EyeIcon" class="" />
            </b-button>
          </template>
        </b-table>
      </b-card>
    </template>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  BInputGroupPrepend,
  BInputGroup,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    BInputGroupPrepend,
    BInputGroup,
    BFormTextarea,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      rights: {},
      loading: false,
      showAll: false,
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      dataLoading: false,
      request: false,
      printing: false,
      saving: false,
      items: [],
      fields: [
        { label: "campus", key: "campus" },
        { label: "no. of registrations", key: "totalRegistration" },
        { label: "no. of admissions", key: "totalEnrollment" },
        { label: "amount", key: "totalAmount" },
        // { label: "actions", key: "actions" },
      ],
      fields2: [
        { label: "campus", key: "campus" },
        { label: "inquiries", key: "inquiries" },
        { label: "registrations", key: "registrations" },
        { label: "admissions", key: "admissions" },
        { label: "amount", key: "amount" },
        // { label: "actions", key: "actions" },
      ],
      allSelected: false,
      showDetails: false,
      selectedID: [],
      totalAmount: 0,
      accLoadList: [],
      accList: [],
      feeList: [],
      loadObj: {
        accountID: 0,
        feesTypeIDs: [],
        dtFrom: null,
        dtTo: null,
        campusIDs: [],
        userID: this.$store.state.userData.userID,
        newAccountID: 0,
      },
      myObj: {},
      rangeDate: null,
      reportDomain: this.$store.state.report.replace(/_/g, ""),
      lastLoad: {
        from: null,
        to: null,
      },
    };
  },
  created() {
    if (!this.$store.state.rights[this.$route.name]) {
      // console.log(this.$store.state.rights[this.$route.name]);
      this.$router.replace({
        name: "misc-not-authorized",
      });
    } else {
      this.rights = this.$store.state.rights[this.$route.name];
      this.LoadData();
      const dt = new Date().toJSON().split("T")[0];
      this.loadObj.dtFrom = dt;
      this.loadObj.dtTo = dt;
      this.lastLoad.from = dt;
      this.lastLoad.to = dt;
      this.rangeDate = `${this.loadObj.dtFrom} to ${this.loadObj.dtTo}`;
      this.LoadRecords();
    }
    // this.LoadData();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    setDate(sd, dateStr) {
      if (dateStr) {
        if (sd.length > 1) {
          let date = dateStr.split(" to ");
          if (date.length == 1) {
            this.loadObj.dtFrom = date[0];
            this.loadObj.dtTo = date[0];
          } else {
            this.loadObj.dtFrom = date[0];
            this.loadObj.dtTo = date[1];
          }
          // this.LoadRecords();
        } else {
          this.loadObj.dtFrom = dateStr;
          this.loadObj.dtTo = null;
        }
      } else {
        this.loadObj.dtFrom = null;
        this.loadObj.dtTo = null;
      }
      // console.log(this.loadObj.dtTo, this.loadObj.dtTo);
    },
    selectFeetype(id) {
      if (this.loadObj.feesTypeIDs.includes(id)) {
        this.loadObj.feesTypeIDs = this.loadObj.feesTypeIDs.filter(
          (el) => el != id
        );
      } else this.loadObj.feesTypeIDs.push(id);
    },
    CheckAccount() {
      var elem = this.$refs["acc"];
      if (this.loadObj.accountID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (!this.loadObj.dtFrom || !this.loadObj.dtTo) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async showData() {
      this.CheckAccount();
      this.CheckDate();
      if (this.CheckAccount() == true && this.CheckDate() == true) {
        // console.log(this.loadObj);
        this.$refs.summaryTable.clearSelected();
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "Fees/LoadPaymentSummary?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId,
          body: this.loadObj,
          context: this,
          subjects: true,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.items = status;
          this.myObj = {
            accountID: this.loadObj.accountID,
            feesTypeIDs: [],
            dtFrom: this.loadObj.dtFrom,
            dtTo: this.loadObj.dtTo,
            campusIDs: [],
            userID: this.$store.state.userData.userID,
            newAccountID: 0,
          };
        } else {
          this.items = [];
          this.myObj = {};
        }
        this.request = false;
      }
    },
    async LoadRecords() {
      if (this.loadObj.dtFrom && this.loadObj.dtTo) {
        this.request = true;

        var obj = {
          url:
            this.$store.state.domain +
            "Users/DashboardInquiries?_db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.$store.state.userData.cId +
            "&dtFrom=" +
            this.loadObj.dtFrom +
            "&dtTo=" +
            this.loadObj.dtTo,
          token: this.$store.state.userData.token,
          dashboard: true,
        };
        let res = await this.get(obj);
        this.items = res.data;
        if (this.items.length > 0) {
          this.myObj = {
            accountID: this.loadObj.accountID,
            feesTypeIDs: [],
            dtFrom: this.loadObj.dtFrom,
            dtTo: this.loadObj.dtTo,
            campusIDs: [],
            userID: this.$store.state.userData.userID,
            newAccountID: 0,
          };
        } else {
          this.myObj = {};
        }
        this.lastLoad = {
          from: this.loadObj.dtFrom,
          to: this.loadObj.dtTo,
        };
        // console.log(this.items);
        this.request = false;
      }
    },
    async print() {
      this.CheckAccount();
      this.CheckDate();
      if (this.CheckAccount() == true && this.CheckDate() == true) {
        // this.printing = true;
        // var url =
        //   `https://${this.reportDomain}.myskool.app/Fees/Show?dbb=` +
        //   this.$store.state.userData.db +
        //   "&cID=" +
        //   this.$store.state.userData.cId +
        //   "&accID=" +
        //   this.loadObj.accountID +
        //   "&dtFrom=" +
        //   this.loadObj.dtFrom +
        //   "&dtTo=" +
        //   this.loadObj.dtTo;
        // window.open(url, "_blank");
        // this.printing = false
      }
    },
    setSelectAll(e) {
      // console.log(e);
      if (e) {
        this.$refs.summaryTable.selectAllRows();
      } else {
        this.$refs.summaryTable.clearSelected();
      }
    },
    setSelected(e, ind) {
      if (e) {
        this.$refs.summaryTable.selectRow(ind);
      } else {
        this.$refs.summaryTable.unselectRow(ind);
      }
      // console.log(e, ind);
    },
    onRowSelect(ch) {
      // console.log(ch);
      this.selectedID = ch.reduce((acc, c) => {
        acc.push(c.campusID);
        return acc;
      }, []);
      this.totalAmount = ch.reduce((total, c) => total + c.amount, 0);
      // this.totalAmount = ch.reduce((total, c) => total + c.totalAmount, 0);

      // console.log(this.selectedID);
    },
    viewDetails(item) {
      console.log(item);
    },
    CheckNewAccount() {
      var elem = this.$refs["d_acc"];
      if (this.myObj.newAccountID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async deposit() {
      if (this.CheckNewAccount() == true) {
        let result = await Swal.fire({
          title: "Are you sure?",
          text: "Do you want to deposit? You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Yes",
        });
        if (result.isConfirmed) {
          this.myObj.campusIDs = this.selectedID;
          this.myObj.dtFrom = this.lastLoad.from;
          this.myObj.dtTo = this.lastLoad.to;
          console.log(this.myObj);
          // this.saving = true;

          // var status = await this.post({
          //   url:
          //     this.$store.state.domain +
          //     "Fees/TransferFeeAccountID?db=" +
          //     this.$store.state.userData.db +
          //     "&cID=" +
          //     this.$store.state.userData.cId,
          //   body: this.myObj,
          //   context: this,
          //   message: "Amount deposited successfully!",
          //   token: this.$store.state.userData.token,
          // });
          // this.saving = false;
          // if (status) {
          //   this.$refs.summaryTable.clearSelected();
          //   this.showData();
          // }
        }
      }
    },
    async LoadData() {
      this.dataLoading = true;

      // var obj = {
      //   url:
      //     this.$store.state.domain +
      //     "FeeType?db=" +
      //     this.$store.state.userData.db +
      //     "&cId=" +
      //     this.$store.state.userData.cId,
      //   token: this.$store.state.userData.token,
      // };
      // this.feeList = await this.get(obj);
      // console.log(this.feeList);

      var obj2 = {
        url:
          this.$store.state.domain +
          "CollectionAccounts?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.accList = await this.get(obj2);
      if (this.reportDomain == "myskoolhpgs") {
        this.accLoadList = this.accList.filter((el) =>
          [18, 20].includes(el.id)
        );
      } else {
        this.accLoadList = [...this.accList];
      }
      if (this.accLoadList.length > 0)
        this.loadObj.accountID = this.accLoadList[0].id;
      this.dataLoading = false;
    },
  },
};
</script>
<style scoped>
.my-collapsing {
  height: 55px;
  overflow: hidden;
}
.my-buttons {
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
